$primaryColor: #1b2831; 
$secondaryColor: #ba9e7a;

.to-left-prim-sec {
	width: 100%;
	height: 100px;
	position: absolute;
	left: 0px;
	background: linear-gradient(to left bottom, $primaryColor 49%, $secondaryColor 50%);
}

.to-right-sec-white {
	width: 100%;
	height: 100px;
	background: linear-gradient(to right bottom, $secondaryColor 49%, white 50%), linear-gradient(-50deg, #ffffff 16px, #000 0);
}