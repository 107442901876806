@import 'common.scss';

.app-container {
	width: 100%;
}

.loading {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	visibility: visible;
	z-index: 10000;
	background-color: $primaryColor;
	opacity: 1;
	transition: visibility 2s, opacity 2s linear;
}

.hide-loading {
	visibility: hidden;
	opacity: 0;
}
