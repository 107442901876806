@import 'common.scss';

@media screen and (max-width: 800px) {
	.content-container {
		padding: 7% !important;
	}

	.equipment-container {
		padding: 7% !important;
	}
}

.content-container {
	background-color: $secondaryColor;
	padding: 5%;
}

.equipment-container {
	padding: 5%;
}
