@import '../style/common.scss';

@media screen and (max-width: 800px) {
	.floors-container {
		padding: 7% !important;
	}

	.apartments-box {
		flex-direction: column !important;
		align-items: center !important;
	}

	.apartment {
		width: 90% !important;
		padding: 5%;
	}

	.apartments-btn {
		margin-top: 5% !important;
	}

	.sold-text {
		font-size: 45px !important;
	}
}

.floors-container {
	padding: 5%;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	overflow: hidden,
}

.grid-item:hover {
	cursor: pointer;
}

.apartments-container {
	padding: 5%;
	width: 90%;
	display: flex;
	flex-direction: column;
}

.apartments-box {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: stretch;
}

.apartment {
	width: 40%;
	padding: 2%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border: 1px solid white;
	align-items: center;
	position: relative;
}

.apartment:hover {
	border: 1px solid $primaryColor;
}

.center-image {
	display: flex !important;
	justify-content: center;
	align-items: center;
}

.plan-image {
	width: 100%;
}

.table-image {
	width: 70%;
}

.apartments-btn {
	border: 2px solid $primaryColor !important;
	font-weight: bold !important;
	align-self: flex-end;
}

.overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.7);
	z-index: 2;
}

.sold-text {
	position: absolute;
	top: 50%;
	left: 50%;
	font-size: 85px;
	color: red;
	font-weight: bold;
	letter-spacing: 10px;
	border: 5px solid red;
	padding: 10px 0 10px 10px;
	text-align: center;
	transform: translate(-50%,-50%) rotate(20deg);
	-ms-transform: translate(-50%,-50%) rotate(20deg);
}
