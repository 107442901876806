@import '../style/common.scss';

@media screen and (max-width: 800px) {
	.footer-logo-image {
		height: 20vh !important;
	}

	.contact-list {
		flex-direction: column !important;
	}

	.contact-icon {
		margin-right: 7% !important;
	}
}

.footer-container {
	width: 100%;
	background-color: $primaryColor;
}

.footer-logo-image {
	height: 25vh;
}

.footer-content {
	width: 100%;
	display: flex !important;
	justify-content: space-around;
	align-items: center;
}

.footer-navigation-list {
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-self: flex-start;
	border-bottom: 3px solid $secondaryColor;
}

.contact-list {
	margin-top: 2% !important;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.contact-item {
	display: flex !important;
	flex-direction: row;
	justify-content: center !important;
	align-items: center !important;
}

.contact-icon {
	margin-right: 3%;
	color: $secondaryColor;
}
