@import '../components/style/common.scss';

html, body {
	margin: 0;
	overflow-x: hidden;
}

::-webkit-scrollbar {
	width: 15px;
  }

::-webkit-scrollbar-track {
	background: white;
}
  
::-webkit-scrollbar-thumb {
	background-color: $primaryColor;
	border-radius: 20px;
}