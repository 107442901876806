@import 'common.scss';
$headerHeight: 12vh;

.header-container {
	width: 100%;
	height: $headerHeight;
	box-shadow: 0px 0px 30px black !important;
}

.header-logo-image {
	height: $headerHeight + 1;
}

.navigation-container {
	display: flex !important;
	justify-content: space-between;
	align-items: center;
}

.navigation-list {
	display: flex;
	justify-content: space-between;
	width: 60%;
}

.tab-link-text {
	text-decoration: none;
	text-transform: uppercase;
	color: white;
}

.tab-link-text:hover {
	color: $secondaryColor;
}

.tab-link-text:focus {
	color: $secondaryColor;
}

.drawer {
	background-color: $primaryColor !important;
	overflow-x: hidden;
}

.drawer-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
}
