@import '../style/common.scss';

@media screen and (max-width: 800px) {
	.contact-container {
		flex-direction: column !important;
		padding: 8% !important
	}

	.contact-map {
		width: 100% !important;
		margin-top: 10%;
		padding-bottom: 70% !important;
	}
}

.contact-container {
	padding: 5%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.contact-map {
	width: 50%;
	border: 5px solid $secondaryColor;
	padding-bottom: 30%;
	z-index: 5;
	overflow: hidden;
	position: relative;
	box-shadow: 5px 10px 25px #555;
}

.contact-map iframe{
	position:absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
}

.contact-container a {
	color: $secondaryColor;
}
