@import 'common.scss';

@media screen and (max-width: 800px) {
	.main-image {
		height: 50vh !important;
		width: 100% !important;
	}

	.text-container {
		padding: 10% !important;
	}

	.location {
		padding: 15% 10% !important;
		flex-direction: column !important;
	}

	.location-image {
		margin-top: 25% !important;
		width: 100% !important;
	}

	.location-btn {
		margin: 0 auto !important;
	}

	.content {
		padding: 10% !important;
		flex-direction: column !important;
	}

	.home-bedroom-image {
		margin-top: 10% !important;
		width: 105% !important;
		margin-left: 0 !important;
	}
}

.main-image {
	width: 100%;
	height: 150vh;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top;
}

.text-container {
	background-color: $primaryColor;
	padding: 5%;
}

.location {
	background-color: $secondaryColor;
	padding: 10% 5% 5%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.location-image {
	width: 50%;
}

.loc-text {
	display: flex;
	flex-direction: column;
}

.location a {
	text-decoration: none;
	color: $primaryColor;
}

.location-btn {
	border: 2px solid $primaryColor !important;
	font-weight: bold !important;
	align-self: flex-end;
}

.home-bedroom-image {
	margin-top: 2%;
	margin-left: 5%;
	width: 45%;
}

.content {
	padding: 5%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.content a {
	text-decoration: none;
	color: $secondaryColor;
}

.content-btn {
	border: 2px solid $secondaryColor !important;
	font-weight: bold !important;
}
