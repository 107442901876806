@media screen and (max-width: 800px) {
	.location-container {
		flex-direction: column !important;
	}

	.location-map {
		width: 100% !important;
		height: 50vh !important;
		order: 1;
	}

	.location-text {
		padding: 10% !important;
		width: 80% !important;
		align-items: center !important;
	}
}

.location-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.location-map {
	width: 50%;
	z-index: 5;
	overflow: hidden;
	position: relative;
	box-shadow: 5px 10px 25px #555;
	align-self: stretch;
}

.location-map iframe {
	position:absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
}

.location-text {
	width: 50%;
	padding: 5%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
}
